import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"




export default function Template({ data }) {
    const { markdownRemark: post } = data;

    return (

        <div>
            <Layout>
                <SEO title={post.frontmatter.title} />
                {/* <img src="https://cdn.pixabay.com/photo/2015/02/26/17/56/clock-650753_1280.jpg" width="100%"></img> */}
                <img src={post.frontmatter.image} width="100%"></img>
                <h1>{post.frontmatter.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </Layout>

        </div>
    )
}

export const postQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                image
                description
            }
        }
    }
`